<template>
  <div class="readings">
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isMeterReadingsRefreshing"
                @click="refreshMeterReadings()"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :table-name="'billings'"
                :show-date-filter="false"
                :show-payment-status-filter="true"
                :show-month-filter="!forSpecificCustomer"
                :show-year-filter="forSpecificCustomer"
                :label="filterLabel"
                @filter="applyFilters"
              />
            </v-col>
            <v-spacer />
          </v-row><v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="activeSearchFilter"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
          <v-row class="ma-4">
            <v-col
              class="text-left col-md-6"
            >
              <span
                class="font-weight-light"
              >Total Expected Amount: </span>
              <span v-show="!isMeterReadingsLoading">Ksh {{ totalExpectedAmount | formatCurrency }}</span>
            </v-col>
            <v-col
              class="text-right col-md-6"
            >
              <span
                class="font-weight-light"
              >Total Amount Paid: </span>
              <span v-show="!isMeterReadingsLoading">Ksh {{ totalAmountPaid | formatCurrency }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="meterReadings"
        :loading="isMeterReadingsLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template
          v-slot:body="{
            items,
          }"
        >
          <tbody v-if="meterReadings.length > 0">
            <tr
              v-for="reading in items"
              :key="reading.id"
              :class="{ clickable: clickableRows }"
              v-on="clickableRows ? { click: () => onMeterReadingClick(reading.id) } : {}"
            >
              <td v-if="clickableRows">
                <span class="primary--text font-weight-medium">{{ reading.account_number }}</span>
              </td>
              <td v-if="clickableRows">
                <span class="primary--text font-weight-medium">{{ reading.user_name }}</span>
              </td>
              <td>{{ reading.previous_reading }}</td>
              <td>{{ reading.current_reading }}</td>
              <td>{{ reading.current_reading - reading.previous_reading }}</td>
              <td>{{ reading.month | formatMonth }}</td>
              <td>Ksh {{ reading.bill | formatCurrency }}</td>
              <td>Ksh {{ reading.meter_billings_sum_amount_paid ? reading.meter_billings_sum_amount_paid : 0 | formatCurrency }}</td>
              <td>Ksh {{ reading.meter_billings_sum_credit ? reading.meter_billings_sum_credit : 0 | formatCurrency }}</td>
              <td>Ksh {{ calculateBalance(reading.meter_billings_sum_amount_paid, reading.meter_billings_sum_credit, reading.bill) | formatCurrency }}</td>
              <td>
                <v-chip
                  text-color="white"
                  :class="{
                    'paid': reading.status === 1,
                    'not-paid': reading.status === 0,
                    'balance': reading.status === 2,
                    'over-paid': reading.status === 3,
                  }"
                >
                  {{ reading.status | formatMeterReadingStatus }}
                </v-chip>
              </td>
              <td @click.stop>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="getMeterReadingsPreviewMessage(reading.id)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiEye }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Preview message</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="editMeter(reading)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPencil }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip
                  v-if="can('meter-reading-edit')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="showResendMeterReadingDialog(reading.id, reading.user_name, reading.month)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiSend }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Resend message</span>
                </v-tooltip>
                <v-tooltip
                  v-if="can('meter-reading-delete')"
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ma-2
                      v-bind="attrs"
                      text
                      icon
                      @click="showDeleteMeterDialog(reading.id)"
                      v-on="on"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isMeterReadingsLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No meter readings found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <data-table-pagination
        :page="pagination.page"
        :page-count="pagination.pageCount"
        @page-change="onPageChange"
        @items-per-page-change="onItemsPerPageChange"
      />
    </v-card>
    <add-meter-reading-dialog
      :show-dialog="showAddMeterDialog"
      :meter-reading="meterReading"
      @close="closeAddMeterDialog()"
      @meter-reading-added="onMeterReadingAdded"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteMeterReading()"
    />
    <confirm-dialog
      :show-dialog="showConfirmResendDialog"
      :is-agree-button-loading="isConfirmResendDialogButtonLoading"
      :agree-text="'Resend'"
      :message="resendTokenMessage"
      @cancel="showConfirmResendDialog = false"
      @agree="resendMeterReading()"
    />
    <v-dialog
      v-model="showPreviewMeterReadingsMessageDialog"
      max-width="450px"
    >
      <v-card>
        <v-card-text id="print-area">
          <h3 class="text-center">
            Progressive Utility Services
          </h3>
          <h4 class="text-center">
            +254722421801 / +254734220666
          </h4><br>
          <meter-reading-preview-message-shimmer v-show="isGetMeterReadingsPreviewMessageLoading" />
          <p
            style="white-space: pre-line"
            class="pt-4"
          >
            {{ meterReadingsPreviewMessage | formatMeterReadingMessage }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="showPreviewMeterReadingsMessageDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="printReceipt"
          >
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiSend, mdiDelete, mdiEye,
} from '@mdi/js'
import moment from 'moment'
import hasPermission from '@/mixins/hasPermission'
import printHtml from '@/mixins/printHtml'
import AddMeterReadingDialog from '@/components/dialogs/AddMeterReadingDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '../partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import MeterReadingPreviewMessageShimmer from '../partials/shimmers/MeterReadingPreviewMessageShimmer.vue'
import defaultMonthFilterDate from '@/mixins/defaultMonthFilterDate'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'

export default {
  components: {
    AddMeterReadingDialog,
    ConfirmDialog,
    SearchInput,
    TableFilter,
    RefreshButton,
    MeterReadingPreviewMessageShimmer,
    DataTablePagination,
  },
  filters: {
    formatMeterReadingMessage(value) {
      const messageChunks = value.split('Pay via')

      return `${messageChunks[0]}\nPay via ${messageChunks[1]}`
    },
  },
  mixins: [hasPermission, defaultMonthFilterDate, printHtml],
  props: {
    meterReadingUrl: {
      type: String,
      default: null,
    },
    clickableRows: {
      type: Boolean,
      default: true,
    },
    showAddMeterDialog: {
      type: Boolean,
      default: false,
    },
    forSpecificCustomer: {
      type: Boolean,
      default: false,
    },
    defaultSortBy: {
      type: String,
      default: 'account_number',
    },
    defaultSortDesc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showConfirmDeleteDialog: false,
      showConfirmResendDialog: false,
      showPreviewMeterReadingsMessageDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isConfirmResendDialogButtonLoading: false,
      toDelete: null,
      sortBy: [this.defaultSortBy],
      sortDesc: [this.defaultSortDesc],
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
      },
      search: '',
      headers: [
        {
          text: 'Account Number',
          value: 'account_number',
        },
        {
          text: 'Customer',
          value: 'user',
          sortable: false,
        },
        {
          text: 'Previous Reading',
          value: 'previous_reading',
        },
        {
          text: 'Current Reading',
          value: 'current_reading',
        },
        {
          text: 'Units consumed',
          value: 'units_consumed',
        },
        {
          text: 'Month',
          value: 'month',
        },
        { text: 'Bill', value: 'bill' },
        { text: 'Amount paid', value: 'amount_paid' },
        { text: 'Credit applied', value: 'credit' },
        { text: 'Balance', value: 'balance' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      activeSearchFilter: 'users.account_number',
      searchFilters: [
        {
          text: 'Account number',
          value: 'users.account_number',
        },
        {
          text: 'Customer',
          value: 'users.name',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiSend,
        mdiDelete,
        mdiEye,
      },
      meterReading: {},
      meterReadings: [],
      isMeterReadingsLoading: true,
      isMeterReadingsRefreshing: false,
      isGetMeterReadingsPreviewMessageLoading: false,
      toResend: '',
      toResendMonth: '',
      toResendCustomerName: '',
      filterLabel: '',
      filters: {
        year: '',
        month: '',
        paymentStatus: [],
      },
      meterReadingsPreviewMessage: '',
      totalExpectedAmount: 0,
      totalAmountPaid: 0,
    }
  },
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.meterReadingUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    resendTokenMessage() {
      const monthName = moment(String(this.toResendMonth)).format('MMMM YYYY')

      return `Are you sure you want to resend ${monthName} meter reading message to ${this.toResendCustomerName}?`
    },
  },
  watch: {
    refreshTable() {
      this.getMeterReadings()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getMeterReadings()
      }
    },
  },
  mounted() {
    if (this.forSpecificCustomer) {
      this.filters.year = new Date().getFullYear()
    } else {
      this.filters.month = this.defaultMonthDate.format('YYYY-MM')
    }
    this.getMeterReadings()
    if (!this.clickableRows) {
      this.removeFirstTwoTableHeader()
    }
  },
  methods: {
    showResendMeterReadingDialog(id, name, month) {
      this.showConfirmResendDialog = true
      this.toResend = id
      this.toResendMonth = month
      this.toResendCustomerName = name
    },
    getMeterReadings: _.debounce(function () {
      this.isMeterReadingsLoading = true
      const status = JSON.stringify(this.filters.paymentStatus)
      axios
        .get(
          `${this.meterReadingUrl}&month=${this.filters.month}&year=${this.filters.year}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&status=${status}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`,
        )
        .then(response => {
          this.totalExpectedAmount = response.data.total_bill
          this.totalAmountPaid = response.data.total_amount_paid
          this.meterReadings = response.data.meter_readings.data
          this.pagination.page = response.data.meter_readings.current_page
          this.pagination.pageCount = response.data.meter_readings.last_page
          this.isMeterReadingsLoading = false
          this.isMeterReadingsRefreshing = false
        })
        .catch(error => {
          this.isMeterReadingsLoading = false
          this.isMeterReadingsRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    getMeterReadingsPreviewMessage(meterReadingId) {
      this.meterReadingsPreviewMessage = ''
      this.showPreviewMeterReadingsMessageDialog = true
      this.isGetMeterReadingsPreviewMessageLoading = true
      axios
        .get(`meter-readings-preview-message/${meterReadingId}`)
        .then(response => {
          this.meterReadingsPreviewMessage = response.data.message
          this.isGetMeterReadingsPreviewMessageLoading = false
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isGetMeterReadingsPreviewMessageLoading = false
        })
    },
    onMeterReadingAdded(month) {
      if (month !== this.filters.month) {
        this.filters.month = month
        this.filterLabel = month
      }
      this.refreshMeterReadings()
    },
    refreshMeterReadings() {
      this.isMeterReadingsRefreshing = true
      this.getMeterReadings()
    },
    resendMeterReading() {
      this.isConfirmResendDialogButtonLoading = true
      axios
        .post(`meter-readings-resend/${this.toResend}`)
        .then(() => {
          this.$notification.success(`Meter reading message has been resend to ${this.toResendCustomerName}`)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmResendDialogButtonLoading = false
          this.showConfirmResendDialog = false
        })
    },
    editMeter(meterReading) {
      this.meterReading = meterReading
      this.$emit('add-meter-dialog-open')
    },
    showDeleteMeterDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    deleteMeterReading() {
      this.isConfirmDeleteDialogButtonLoading = true
      axios
        .delete(`meter-readings/${this.toDelete}`)
        .then(() => {
          this.$notification.success('Meter reading deleted successfully')
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
          this.getMeterReadings()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
          this.isConfirmDeleteDialogButtonLoading = false
          this.showConfirmDeleteDialog = false
        })
    },
    onMeterReadingClick(meterReadingId) {
      this.$router.push({ name: 'meter-readings-details', params: { id: meterReadingId } })
    },
    closeAddMeterDialog() {
      this.meterReading = {}
      this.$emit('add-meter-dialog-close')
    },
    removeFirstTwoTableHeader() {
      this.headers.splice(0, 2)
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getMeterReadings()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getMeterReadings()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    applyFilters(filters) {
      this.filters = filters
      this.getMeterReadings()
    },
    calculateBalance(amountPaid, creditApplied, bill) {
      const amount = parseFloat(amountPaid) || 0
      const credit = parseFloat(creditApplied) || 0
      const balance = parseFloat(bill) - (amount + credit)

      if (balance < 0) {
        return 0
      }

      return balance
    },
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.v-chip.not-paid {
  background: #F44336;
}
.v-chip.paid {
  background: #4CAF50;
}
.v-chip.balance {
  background: #FFA726;
}
.v-chip.over-paid {
  background: #2196F3;
}
</style>
